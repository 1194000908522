
export const computePercentage = (item) => {
    if (item == 0) return "hashrate-gray";
    else if (item > 0 && item < 85) return "hashrate-red";
    else if (item >= 85 && item < 95) return "hashrate-yellow";
    else return "hashrate-green";
}

export const getCurrentObjValue = (target, id) => {
    return id !== '' && target.filter(res => res.id == id).length > 0 ? target.filter(res => res.id == id)[0].value : ""
}

export const computedRouteUrl = (route) => {
    const res = { url: "" };
    Object.keys(route.query).forEach((obj, i) => {
        const bindedUrl = `${obj}=${route.query[obj]}`;
        if (i === 0) res['url'] += `?${bindedUrl}`
        else res['url'] += `&${bindedUrl}`
    });
    return res['url'];
}
